import { EditorType } from '@wix/platform-editor-sdk';
import type {
  PageData,
  ComponentRef,
  EditorSDK,
  PageRef,
  ContextParams,
} from '@wix/platform-editor-sdk';
import type { BehaviorObject } from '@wix/document-services-types';
import type {
  ConnectedComponentsBuilder,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
  PlatformControllerFlowAPI,
  TFunction,
  VisitorLogger,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import { BIReporterService, type IBIReporterService } from 'root/services/biReporterService';
import { getRole, setWidgetDesignTabs } from 'root/utils/utils';
import type { WidgetDesignTabsArray } from 'root/types/widgets';
import { PAGE_DATA } from 'root/appConsts/consts';
import { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import { OperationsClient } from 'root/api/operationClient';
import type { ValueOf } from 'root/types/valueOf';
import type { Operation } from 'root/types/businessTypes';
import { duplicatePage, updatePage } from './installationUtils';

const STORAGE_KEYS = {
  PAGES: 'pages',
} as const;

class EditorUtilsStorage {
  private storage = new Map<string, unknown>();

  set<T>(key: ValueOf<typeof STORAGE_KEYS>, value: T) {
    this.storage.set(key, value);
  }

  get<T>(key: ValueOf<typeof STORAGE_KEYS>) {
    return this.storage.get(key) as T;
  }
}

const editorUtilsStorage = new EditorUtilsStorage();

export const getIsAppInstalled = async (
  editorSDK: FlowEditorSDK,
  appDefId: string
): Promise<boolean> => {
  return editorSDK.document.application.isApplicationInstalled('_token', {
    appDefinitionId: appDefId,
  });
};

export const isPageInstalledByAppDefId = async (
  editorSDK: FlowEditorSDK,
  appDefId: string
): Promise<boolean> => {
  return isPageInstalled(editorSDK, (page: PageData) => page.appDefinitionId === appDefId);
};

export const isPageInstalledByTpaPageId = async (
  editorSDK: FlowEditorSDK,
  tpaPageId: string
): Promise<boolean> => {
  return isPageInstalled(
    editorSDK,
    (page: PageData) => page.tpaPageId?.includes(tpaPageId) ?? false
  );
};

export const isPageInstalled = async (
  editorSDK: FlowEditorSDK,
  pagePredicate: (page: PageData) => boolean
): Promise<boolean> => {
  const installedPages = await editorSDK.pages.data.getAll('_token');
  return installedPages.some(pagePredicate);
};

export const getMissingPopups = async (
  editorSDK: FlowEditorSDK,
  tpaPageIds: string[]
): Promise<string[]> => {
  const installedPopups = (await getOloPopups(editorSDK)).map((popups) => popups.tpaPageId ?? '');

  const missingPopups = tpaPageIds.filter((popup) => !installedPopups.includes(popup));
  return missingPopups;
};

const installApp = async (
  editorSDK: FlowEditorSDK,
  appDefId: string
): Promise<{ instanceId: string }> => {
  return editorSDK.document.application.add('_token', {
    appDefinitionId: appDefId,
    isSilent: true,
  });
};

export const installAppIfMissing = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  biReporterService: IBIReporterService,
  fedopsLogger: FedopsLogger,
  isFirstInstall: boolean
) => {
  try {
    isFirstInstall && fedopsLogger.installAppIfMissingStarted();
    const isAppInstalled = await getIsAppInstalled(editorSDK, appDefId);

    if (!isAppInstalled) {
      // eslint-disable-next-line no-console
      console.log('Online orders - Installing app:', appDefId);
      await installApp(editorSDK, appDefId);
      biReporterService?.reportOloEditorInstallationStepsEvent({
        step: 'app_dependency_installed',
        msid: await editorSDK?.info.getMetaSiteId('_token'),
        appDefId,
        isFirstInstall,
        isInstallationRetry: !isFirstInstall,
      });
    }
    isFirstInstall && fedopsLogger.installAppIfMissingEnded();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // [TODO] - send Sentry error here
    // eslint-disable-next-line no-console
    console.error('Online orders - issue while installing app', appDefId, e.message);
  }
};

const getPageRef = (editorSDK: FlowEditorSDK, tpaPageId: string): Promise<ComponentRef> => {
  return editorSDK.tpa.getPageRefByTPAPageId('_token', {
    tpaPageId,
  });
};

export const navigateToPage = async (
  editorSDK: FlowEditorSDK,
  tpaPageId: string
): Promise<void> => {
  const pageRef = await getPageRef(editorSDK, tpaPageId);
  return navigateToPageByPageRef(editorSDK, pageRef);
};

export const navigateToPageByPageRef = async (
  editorSDK: FlowEditorSDK,
  pageRef: PageRef
): Promise<void> => {
  return editorSDK.document.pages.navigateTo('_token', { pageRef });
};

const getOloPopups = (editorSDK: FlowEditorSDK) =>
  editorSDK.pages.popupPages.getApplicationPopups('_token', {
    includeUnmanaged: true,
  });

export const disablePopupsAutoOpen = async (editorSDK: FlowEditorSDK) => {
  const pagesData = await editorSDK.pages.data.getAll('_token');
  const allPageRefs = pagesData.map((page) => ({
    id: page.id,
    type: 'DESKTOP',
  })) as unknown as PageRef[];

  const installedPopups = (await getOloPopups(editorSDK))
    .filter((popup) => popup.appDefinitionId === APP_DEF_IDS.orders)
    .map((popup) => popup.id ?? '');

  allPageRefs.forEach(async (pageRef) => {
    const behaviors = (await editorSDK.components.behaviors.get('_token', {
      componentRef: pageRef,
    })) as BehaviorObject[];

    const autoOpenBehaviors = behaviors?.filter(
      (behavior) =>
        behavior.action.name === 'load' &&
        behavior.behavior.name === 'openPopup' &&
        installedPopups.includes(behavior.behavior.targetId)
    );

    autoOpenBehaviors?.forEach(async (behavior) => {
      // eslint-disable-next-line no-console
      console.log('Online orders - removing auto open behavior: ', behavior);

      await editorSDK.components.behaviors.remove('_token', {
        componentRef: pageRef,
        // @ts-expect-error object type is valid here - Document Management also accepts it: https://github.com/wix-private/document-management/blob/340ef4a8c29ef22503fd3303fa4e60dd775c4362/document-services-implementation/src/actionsAndBehaviors/actionsAndBehaviors.ts#L334
        behaviorName: behavior,
      });
    });
  });
};

export const showInstallationProgressBar = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
  shouldOpenProgressBar: boolean
): Promise<void> => {
  if (shouldOpenProgressBar) {
    return editorSDK.editor.openProgressBar('_token', {
      title: t('app.installation.progressBar.title'),
      totalSteps: 3,
      currentStep: 0,
    });
  }
};

export const hideInstallationProgressBar = async (
  editorSDK: FlowEditorSDK,
  shouldOpenProgressBar: boolean
): Promise<void> => {
  try {
    if (shouldOpenProgressBar) {
      return editorSDK.editor.closeProgressBar('_token', {});
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error('closeProgressBar failed', e.message);
  }
};

export const progressBarMoveToStepNumber = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
  nextStep: number,
  shouldOpenProgressBar: boolean
): Promise<void> => {
  try {
    if (shouldOpenProgressBar) {
      const stepTitle = t(`app.installation.progressBar.step-${nextStep}`);
      return editorSDK.editor.updateProgressBar('_token', {
        currentStep: nextStep,
        stepTitle,
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.error('closeProgressBar failed', e.message);
  }
};

export const openProgressBar = async (editorSDK: FlowEditorSDK, title: string): Promise<void> => {
  try {
    await editorSDK.editor.openProgressBar('_token', {
      totalSteps: 1,
      title,
    });
  } catch (e: unknown) {}
};

async function findAsyncSequential<T>(
  array: T[],
  predicate: (t: T) => Promise<boolean>
): Promise<T | undefined> {
  for (const t of array) {
    if (await predicate(t)) {
      return t;
    }
  }
  return undefined;
}

export const getComponentByTitle = async (editorSDK: EditorSDK, title: string) => {
  const components = await editorSDK.components.getAllComponents('token');
  const comp = await findAsyncSequential<ComponentRef>(components, async (componentRef) => {
    const compData = (await editorSDK.components.data.get('token', { componentRef })) as {
      title: string;
    };
    return compData?.title === title;
  });
  return comp;
};

export const getComponentByWidgetId = async (editorSDK: EditorSDK, widgetId: string) => {
  const components = await editorSDK.components.getAllComponents('token');
  const comp = await findAsyncSequential<ComponentRef>(components, async (componentRef) => {
    const compData = (await editorSDK.components.data.get('token', { componentRef })) as {
      widgetId: string;
    };
    return compData?.widgetId === widgetId;
  });
  return comp;
};

export const getComponentAncestorByIdentifier = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
  widgetIdentifier: Record<string, unknown>
): Promise<ComponentRef | undefined> => {
  const ancestors = await editorSDK.components.getAncestors('', { componentRef });
  for (const ancestor of ancestors) {
    try {
      const data = await editorSDK.components.data.get('', { componentRef: ancestor });
      const isMatch = Object.keys(widgetIdentifier).every(
        // @ts-expect-error
        (key) => data && data[key] === widgetIdentifier[key]
      );
      if (isMatch) {
        return ancestor;
      }
    } catch (e: unknown) {}
  }
};

export const getParentWidgetById = async (
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
  id: string
): Promise<ComponentRef | undefined> => {
  return getComponentAncestorByIdentifier(editorSDK, componentRef, { controllerType: id });
};

export const updateWidgetLayout = async (editorSDK: EditorSDK, componentRef: ComponentRef) => {
  const [container] = await editorSDK.components.getChildren('token', { componentRef });
  const [widget] = await editorSDK.components.getChildren('token', {
    componentRef: container,
  });

  widget &&
    (await editorSDK.components.layout.update('token', {
      componentRef: widget,
      layout: { fixedPosition: false },
    }));
};

export const disableElementSelection = (
  componentBuilder: ConnectedComponentsBuilder,
  hideFromHierarchy = true
) => {
  componentBuilder.behavior().set({ closed: { hideFromHierarchy, selectable: false } });
};

export const disableElementsSelection = (
  widgetBuilder: WidgetBuilder,
  components: string[],
  hideFromHierarchy = true
) => {
  components.forEach((componentId) => {
    widgetBuilder.configureConnectedComponents(getRole(componentId), (componentBuilder) => {
      disableElementSelection(componentBuilder, hideFromHierarchy);
    });
  });
};

export const configureWidgetDesign = ({
  widgetBuilder,
  title,
  tabs,
  t,
  helpId,
}: {
  widgetBuilder: WidgetBuilder | ConnectedComponentsBuilder;
  title: string;
  tabs: WidgetDesignTabsArray;
  t: TFunction;
  helpId?: string;
}) => {
  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title,
      customHelpId: helpId,
    });
    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    setWidgetDesignTabs(widgetDesignTabsBuilder, tabs, t);
  });
};

export const isMobileViewport = async (editorSDK: EditorSDK) => {
  const editorMode = await editorSDK.info.getCurrentViewport();
  return editorMode.type === 'MOBILE';
};

export const getOrderPages = async (editorSDK: FlowEditorSDK) => {
  const applicationPages = await editorSDK.pages.data.getAll('_token');
  return applicationPages.filter(
    (page) =>
      page.appDefinitionId === APP_DEF_IDS.orders && page.tpaPageId?.startsWith(PAGE_DATA.pageId)
  );
};

export const getOrdersPageById = async (editorSDK: FlowEditorSDK, pageId: string) => {
  const ordersPages =
    editorUtilsStorage.get<PageData[]>(STORAGE_KEYS.PAGES) ??
    (await editorSDK.pages.data.getAll('_token'));
  return {
    ordersPage: ordersPages.find(
      (page) => page.appDefinitionId === APP_DEF_IDS.orders && page.id === pageId
    ),
    numOfPages: ordersPages.length,
  };
};

export const getCurrentPage = async (editorSDK: FlowEditorSDK) => {
  const { id: pageId } = await editorSDK.document.pages.getCurrent('token');
  const { ordersPage } = await getOrdersPageById(editorSDK, pageId);
  return ordersPage;
};

export const getOperationByPageId = (pageId: string | undefined) => {
  return pageId?.slice(PAGE_DATA.pageId.length + 1);
};

export const setOrdersPageState = async (editorSDK: FlowEditorSDK) => {
  const ordersPages = await getOrderPages(editorSDK);
  if (ordersPages.length) {
    const state: Record<string, PageRef[]> = {};
    ordersPages.forEach((ordersPage) => {
      state[ordersPage.tpaPageId!] = [{ id: ordersPage.id! }] as PageRef[];
    });

    editorSDK.document.pages.setState('', {
      state,
    });
  }
};

export const getEditorOptions = (options: FlowPlatformOptions) => {
  const isFirstInstall = options.firstInstall;
  const isResponsive = options.origin.type === EditorType.Responsive;
  const isStudio = options.origin.subType === 'STUDIO';
  return { isFirstInstall, isResponsive, isStudio };
};

export const createBILogger = ({
  flowAPI,
  options,
}: {
  flowAPI: EditorScriptFlowAPI;
  options: ContextParams;
}) => {
  const bi = options.essentials.biLoggerFactory().logger() as unknown as VisitorLogger;
  return BIReporterService({
    biLogger: bi,
    origin: options.origin,
    environment: flowAPI.environment as unknown as PlatformControllerFlowAPI['environment'],
  });
};

export const createFedopsLogger = async ({
  editorSDK,
  flowAPI,
  biReporterService,
}: {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  biReporterService: IBIReporterService;
}) => {
  const { fedops } = flowAPI;
  const msid = await editorSDK?.info.getMetaSiteId('_token');

  return new FedopsLogger(fedops, msid, biReporterService);
};

export const getManifestFedopsEvent = (fedopsLogger: FedopsLogger, isFirstInstall: boolean) => {
  return isFirstInstall
    ? {
        started: fedopsLogger.appManifestFirstInstallStarted,
        ended: fedopsLogger.appManifestFirstInstallEnded,
      }
    : {
        started: fedopsLogger.appManifestLoadStarted,
        ended: fedopsLogger.appManifestLoadEnded,
      };
};

export const syncOrdersPageStorage = async (editorSDK: EditorSDK) => {
  const updatedOrdersPages = await getOrderPages(editorSDK);
  editorUtilsStorage.set<PageData[]>(STORAGE_KEYS.PAGES, updatedOrdersPages);
};

const notMigratedOloPages = (ordersPages: PageData[]) =>
  ordersPages.filter((page) => page.tpaPageId === PAGE_DATA.pageId);

export const getPageRefByOperationId = async (editorSDK: EditorSDK, operationId: string) => {
  const page = (await editorSDK.pages.data.getAll('token')).find(
    (pageData) =>
      pageData.appDefinitionId === APP_DEF_IDS.orders &&
      pageData.tpaPageId === `${PAGE_DATA.pageId}-${operationId}`
  );

  return {
    id: page?.id,
  } as PageRef;
};

export const syncPagesByOperations = async ({
  editorSDK,
  flowAPI,
  fedopsLogger,
  biReporterService,
  isStudio,
  msid,
  operationList,
}: {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  fedopsLogger?: FedopsLogger;
  biReporterService?: IBIReporterService;
  isStudio: boolean;
  msid: string;
  operationList?: Operation[];
}) => {
  const { httpClient, translations } = flowAPI;
  const operations = operationList ?? (await new OperationsClient(httpClient).getOperations());
  if (!operations?.length) {
    return;
  }
  const ordersPages = await getOrderPages(editorSDK);

  const notMigratedPages = notMigratedOloPages(ordersPages);
  const [notMigratedPage] = notMigratedPages;

  notMigratedPages.length &&
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'OLO_pages_not_migrated',
      msid,
      isStudio,
      value: `pages: ${notMigratedPages.map((page) => ({
        title: page.title,
        tpaPageId: page.tpaPageId,
        pageUriSEO: page.pageUriSEO,
      }))}}`,
    });

  if (notMigratedPage && operations.length === 1) {
    const [operation] = operations;
    const pageRef = { id: notMigratedPage.id } as unknown as PageRef;
    await updatePage(editorSDK, pageRef, operation.id);
    await syncOrdersPageStorage(editorSDK);
    biReporterService?.reportOloEditorInstallationStepsEvent({
      step: 'OLO_page_migrated',
      msid,
      isStudio,
      value: `{operation: ${operation.id}}`,
    });
    return;
  }

  const ordersPageIds = ordersPages.map((page) => page.tpaPageId);
  const pagePromises: Promise<object>[] = [];
  const operationNames = [];
  for (const operation of operations) {
    const pageId = `${PAGE_DATA.pageId}-${operation?.id}`;
    if (!ordersPageIds.includes(pageId) && operation) {
      const pagePromise = createPageByOperation({
        editorSDK,
        fedopsLogger,
        biReporterService,
        msid,
        operation,
        sourcePage: ordersPages[0],
      });
      operationNames.push(operation.name);
      pagePromises.push(pagePromise);
    }
  }

  if (pagePromises.length) {
    const [pageName] = operationNames;
    const t = translations.t as TFunction;
    await openProgressBar(editorSDK, t('editor.create-new-page.progress-bar.title', { pageName }));

    try {
      await Promise.all(pagePromises);
    } catch (e: unknown) {
      console.error('syncPagesByOperations failed ', e);
      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: 'failed to create OLO page by operation',
        value: {
          msid,
          isStudio,
          operationNames,
        },
      });
    } finally {
      if (isStudio) {
        await hideInstallationProgressBar(editorSDK, true);
      }
    }
  }
  await syncOrdersPageStorage(editorSDK);
};

const SPACE_CHARACTERS = /\s+/g;
const NON_UNICODE_CHARACTERS = /[^\p{L}\p{N}-]/gu;

const createPageByOperation = async ({
  editorSDK,
  fedopsLogger,
  biReporterService,
  msid,
  operation,
  sourcePage,
}: {
  editorSDK: EditorSDK;
  fedopsLogger?: FedopsLogger;
  biReporterService?: IBIReporterService;
  msid: string;
  operation: Operation;
  sourcePage: PageData;
}) => {
  const title = operation.name;
  const pageUriSEO = `${PAGE_DATA.pageUriSEO}-${operation.name}`
    .replace(SPACE_CHARACTERS, '-')
    .replace(NON_UNICODE_CHARACTERS, '');

  fedopsLogger?.createOLOPageByOperationStarted();
  const pageRef = await duplicatePage(editorSDK, sourcePage.id ?? '');
  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'duplicate page',
    msid,
    value: `{pageRef: ${pageRef.id}, operationId: ${operation.id}}`,
  });

  await updatePage(editorSDK, pageRef, operation.id, title, pageUriSEO);

  // there is no way to know if the page was updated, so we wait for 2 seconds and check if the page was updated
  setTimeout(async () => {
    const currentPage = pageRef ? await getCurrentPage(editorSDK) : undefined;
    const isUpdated = currentPage?.tpaPageId === `${PAGE_DATA.pageId}-${operation.id}`;
    biReporterService?.reportOloEditorPageAddedBiEvent({
      operationId: operation.id,
      isDuplicated: !!pageRef,
      isUpdated,
    });
  }, 2000);
  await navigateToPageByPageRef(editorSDK, pageRef);
  await openRestaurantsPagesPanel(editorSDK);

  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'update duplicated page',
    msid,
    value: `{pageRef: ${pageRef.id}, title, ${title}, operationId: ${operation.id}}`,
  });
  fedopsLogger?.createOLOPageByOperationStarted();
  return pageRef;
};

const openRestaurantsPagesPanel = async (editorSDK: EditorSDK) => {
  const { check, show } = editorSDK.editor.deeplink;
  const pagesPanel = {
    type: 'pagesPanel' as 'pagesPanel',
    params: [APP_DEF_IDS.restaurants],
  };
  const isPanelExists = await check('_token', pagesPanel);
  isPanelExists && (await show('_token', pagesPanel));
};
