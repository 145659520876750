import {
  oloLiveSiteOloPageLoaded,
  restaurantsUouPageFinishedLoading,
  restaurantsUouPageStartedLoading,
  restaurantsOloLiveSiteClickOnFulfillment,
  restaurantsOloLiveSiteDispatchModalOpened,
  oloLiveSiteClickOnItem,
  oloLiveSiteItemModalOpened,
  oloLiveSiteAddDishToCart,
  restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuBar,
  restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuDropdownAndOtherMenusInside,
  restaurantsOloLiveSiteClicksInsideDispatchModal,
  restaurantsOloLiveSiteErrorDisplayedInDispatchModal,
  restaurantsOloLiveSiteDispatchModalClosed,
  oloGenericDebugEvent,
  oloEditorInstallationSteps,
  restaurantsEditorPanelEditAction,
  oloEditorMenuReorder,
  oloEditorOrderPageWasAddedToEditorSrc159Evid561,
  oloEditorOrderPageWasDeletedFromEditorSrc159Evid563,
  oloEditorViewOrderClickSrc159Evid555,
  restaurantsEditorPanelLoad,
} from '@wix/bi-logger-restaurants-data/v2';
import type { SchedulingType, TimeSlot } from '../types/businessTypes';
import { DispatchType } from '../types/businessTypes';
import type { PopulatedMenu } from '../types/menusTypes';
import { state } from '../states/RootState';
import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type {
  OloPageLoadedParams,
  OloPageStartedLoadingParams,
  OloPageFinishedLoadingParams,
  LiveSiteClickFulfillmentOrigin,
  OloLiveSiteClickOnFulfillmentParams,
  OloLiveSiteDispatchModalOpenedParams,
  OloLiveSiteClickOnItemParams,
  OloLiveSiteItemModalOpenedParams,
  OloLiveSiteAddDishToCartParams,
  OloLiveSiteClicksOnMenuBarParams,
  LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName,
  OloLiveSiteClicksOnMenuDropdownAndOptionInsideParams,
  OloLiveSiteClicksInsideDispatchModalParams,
  LiveSiteClicksInsideDispatchModalButtonType,
  OloLiveSiteErrorDisplayedInDispatchModalParams,
  LiveSiteDispatchModalClosedTriggerName,
  OloLiveSiteDispatchModalClosedParams,
  OloGenericDebugParams,
  OloEditorInstallationStepsParams,
  OloEditorMenuReorderParams,
} from '@wix/restaurants-bi';
import {
  LivesiteFulfillmentType,
  LivesitePageLoadModeType,
  LivesitePageLoadStatusDisplayedType,
  LivesiteClickFulfillmentStatusDisplayedType,
  LiveSiteDispatchModalOpenedSchedulingType,
  PageName,
  WidgetName,
  Product,
  LiveSiteDispatchModalClosedSelectedRadioButton,
  LiveSiteDispatchModalClosedSchedulingType,
} from '@wix/restaurants-bi';
import type { Origin } from '@wix/editor-platform-sdk-types';
import { DEFAULT_ADDRESS, PAGE_DATA } from '../appConsts/consts';
import {
  getAmountOfItemsInCart,
  getAmountOfSectionsAndItems,
  getHostingEnvironment,
  getErrorType,
  getSelectedRadioButton,
} from '../utils/biUtils';
import type { VisitorLogger } from '@wix/yoshi-flow-editor/external-types/bi';
import type { ErrorType } from 'root/components/DispatchModal/dispatchModalUtils';
import { dispatchState } from 'root/states/DispatchState';
import type { CartButtonNavigationTarget } from 'root/types/cartButton';

type ItemModalCommonParams = {
  itemId: string;
  itemName?: string;
  menuId?: string;
  num_labels?: number;
  sectionId?: string;
};

type LiveSiteOloPageLoadedParams = {
  refferalInfo?: string;
  isMemberLoggedIn: boolean;
  menus: PopulatedMenu[];
};

type ClickOnFulfillmentParams = {
  origin: LiveSiteClickFulfillmentOrigin;
  dispatchType?: DispatchType;
  isMemberLoggedIn?: boolean;
};

type DispatchModalOpenedParams = {
  schedulingType: SchedulingType;
  isAsapDisplayed?: boolean;
  isPreorderDisplayed?: boolean;
  availableDispatchTypes?: DispatchType[];
  currentFulfillment: DispatchType;
  operationId: string;
  selectedSchedulingRadioButton?: SchedulingType;
  isMemberLoggedIn?: boolean;
  selectedTime?: TimeSlot;
  businessDays?: number;
};

type ClickOnItemParams = ItemModalCommonParams & {
  minItemPrice: number;
  operationId?: string;
  isMenuItemAvailable?: boolean;
  isItemInStock?: boolean;
};

type ItemModalOpenedParams = ItemModalCommonParams & {
  minItemPrice: number;
  numModifiers?: number;
  numModifiersGroups?: number;
  numPriceVariations?: number;
  isMenuItemAvailable?: boolean;
  isItemInStock?: boolean;
  operationId?: string;
};

type AddDishToCartParams = ItemModalCommonParams & {
  labelsList: string;
  itemPrice: number;
  numSelectedModifiers: number;
  numModifiersGroups?: number;
  numPriceVariations?: number;
  amountOfItems: number;
  priceVariationName?: string;
  isSuccess: boolean;
  selectedModifiersList?: string;
  operationId?: string;
};

type ClicksOnMenuDropdownAndOptionParams = {
  buttonName: LiveSiteClicksOnMenuDropdownAndOptionInsideButtonName;
  currentMenuId?: string;
  currentMenuName?: string;
  currentMenuNumber?: number;
  menuId?: string;
  menuName?: string;
  menuNumber?: number;
  numTotalMenus?: number;
  operationId?: string;
};

type ClicksOnMenuBarParams = {
  currentSectionNumber?: number;
  numTotalSections?: number;
  operationId?: string;
  sectionId?: string;
  sectionName?: string;
};

type ClicksInsideDispatchModalParams = {
  dispatchType: DispatchType;
  isPreorder?: boolean;
  buttonType: LiveSiteClicksInsideDispatchModalButtonType;
  operationId?: string;
};

type ErrorDisplayedInDispatchModalParams = {
  dispatchType: DispatchType;
  errorType: ErrorType;
  errorMessage?: string;
};

type DispatchModalClosedParams = {
  dispatchType: DispatchType;
  triggerName: LiveSiteDispatchModalClosedTriggerName;
  schedulingType: SchedulingType;
  isAsapDisplayed?: boolean;
  isPreorderDisplayed?: boolean;
  availableDispatchTypes?: DispatchType[];
  operationId: string;
  selectedSchedulingRadioButton?: SchedulingType;
  isMemberLoggedIn?: boolean;
  selectedTime?: TimeSlot;
  businessDays?: number;
};

type GenericDebugEventParams = {
  subjectType?: string;
  value?: object;
};

type EditorInstallationStepsParams = {
  isFirstInstall?: boolean;
  isInstallationRetry?: boolean;
  step: string;
  value?: string;
  isStudio?: boolean;
  appDefId?: string;
  msid?: string;
  errorMessage?: string;
  lightboxId?: string;
  lightboxTitle?: string;
  lightboxWidgetId?: string;
  lightboxLayout?: string;
  lightboxParentRef?: string;
};

type EditorMenuReorderParams = {
  menuId: string;
  menuName: string;
  numMenus: number;
  indexListBeforeChange: string;
  indexListAfterChange: string;
};

type CartButtonClickParams = {
  isMemberLoggedIn: boolean;
  cartState: number;
  navigationTarget: CartButtonNavigationTarget;
};

type PanelEditActionParams = {
  panelName: string;
  value: string;
  widgetId: string;
  widgetName: string;
  pageId: string;
  msid: string;
  firstLevel: string;
  lastLevel?: string;
};

type PanelLoadParams = {
  widgetId: string;
  pageId: string;
  widgetName: string;
  panelName: string;
  origin: string;
  msid: string;
};

type EditorPageAddedParams = {
  operationId?: string;
  isDuplicated: boolean;
  isUpdated: boolean;
};

type EditorPageDeletedParams = {
  operationId?: string;
  isSuccessful: boolean;
};

export interface IBIReporterService {
  reportPanelLoaded: (params: PanelLoadParams) => void;
  reportPanelEditAction: (params: PanelEditActionParams) => void;
  reportOloLiveSiteClickOnCartButton: (params: CartButtonClickParams) => void;
  reportOloLiveSiteOloPageLoadedBiEvent: ({
    refferalInfo,
    isMemberLoggedIn,
    menus,
  }: LiveSiteOloPageLoadedParams) => void;
  reportRestaurantsUouPageStartedLoadingBiEvent: () => void;
  reportRestaurantsUouPageFinishedLoadingBiEvent: () => void;
  reportOloLiveSiteClickOnFulfillmentBiEvent: ({
    origin,
    dispatchType,
    isMemberLoggedIn,
  }: ClickOnFulfillmentParams) => void;
  reportOloLiveSiteDispatchModalOpenedBiEvent: ({
    schedulingType,
    isAsapDisplayed,
    availableDispatchTypes,
    isPreorderDisplayed,
    currentFulfillment,
    operationId,
    isMemberLoggedIn,
    selectedSchedulingRadioButton,
    selectedTime,
    businessDays,
  }: DispatchModalOpenedParams) => void;
  reportOloLiveSiteClickOnItemBiEvent: ({
    itemId,
    itemName,
    menuId,
    sectionId,
    minItemPrice,
    isMenuItemAvailable,
  }: ClickOnItemParams) => void;
  reportOloLiveSiteItemModalOpenedBiEvent: ({
    itemId,
    itemName,
    menuId,
    sectionId,
    minItemPrice,
    num_labels,
    numModifiers,
    numModifiersGroups,
    numPriceVariations,
    operationId,
  }: ItemModalOpenedParams) => void;
  reportOloLiveSiteAddDishToCartBiEvent: ({
    itemId,
    itemName,
    menuId,
    sectionId,
    num_labels,
    numSelectedModifiers,
    priceVariationName,
    selectedModifiersList,
    amountOfItems,
    itemPrice,
    isSuccess,
    labelsList,
    operationId,
  }: AddDishToCartParams) => void;
  reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent: ({
    buttonName,
    currentMenuId,
    currentMenuName,
    currentMenuNumber,
    operationId,
    numTotalMenus,
    menuNumber,
    menuName,
    menuId,
  }: ClicksOnMenuDropdownAndOptionParams) => void;
  reportOloLiveSiteClicksOnMenuBarBiEvent: ({
    currentSectionNumber,
    numTotalSections,
    operationId,
    sectionId,
    sectionName,
  }: ClicksOnMenuBarParams) => void;
  reportOloLiveSiteClicksInsideDispatchModalBiEvent: ({
    dispatchType,
    isPreorder,
    buttonType,
    operationId,
  }: ClicksInsideDispatchModalParams) => void;
  reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent: ({
    dispatchType,
    errorType,
    errorMessage,
  }: ErrorDisplayedInDispatchModalParams) => void;
  reportOloLiveSiteDispatchModalClosedBiEvent: ({
    dispatchType,
    triggerName,
    operationId,
    selectedSchedulingRadioButton,
    isAsapDisplayed,
    isPreorderDisplayed,
    schedulingType,
    availableDispatchTypes,
    isMemberLoggedIn,
    selectedTime,
    businessDays,
  }: DispatchModalClosedParams) => void;
  reportOloGenericDebugBiEvent: ({ subjectType, value }: GenericDebugEventParams) => void;
  reportOloEditorInstallationStepsEvent: ({ step }: EditorInstallationStepsParams) => void;
  reportOloEditorMenuReorderBiEvent: ({
    menuId,
    menuName,
    numMenus,
    indexListBeforeChange,
    indexListAfterChange,
  }: EditorMenuReorderParams) => void;
  reportOloEditorPageAddedBiEvent: ({
    operationId,
    isDuplicated,
    isUpdated,
  }: EditorPageAddedParams) => void;

  reportOloEditorPageDeletedBiEvent: ({
    operationId,
    isSuccessful,
  }: EditorPageDeletedParams) => void;
}

export const BIReporterService = ({
  biLogger,
  environment,
  origin,
  resolution,
  widgetInstanceId = '',
}: {
  biLogger?: VisitorLogger | null;
  environment: PlatformControllerFlowAPI['environment'];
  origin?: Origin;
  resolution?: {
    width: number;
    height: number;
  };
  widgetInstanceId?: string;
}): IBIReporterService => {
  const biReporterObject = {
    reportPanelLoaded: (params: PanelLoadParams) => {
      biLogger?.report(
        restaurantsEditorPanelLoad({
          widgetInstanceId,
          product: Product.OLO,
          ...params,
        })
      );
    },
    reportPanelEditAction: (params: PanelEditActionParams) => {
      const { widgetId, widgetName, panelName, firstLevel, lastLevel, value, pageId, msid } =
        params;
      biLogger?.report(
        restaurantsEditorPanelEditAction({
          widgetId,
          widgetName,
          firstLevel,
          lastLevel: lastLevel ?? firstLevel,
          panelName,
          value,
          msid,
          widgetInstanceId,
          pageId,
          product: Product.OLO,
        })
      );
    },
    reportOloLiveSiteClickOnCartButton: (params: CartButtonClickParams) => {
      const { cartState, isMemberLoggedIn, navigationTarget } = params;
      biLogger?.report(
        oloEditorViewOrderClickSrc159Evid555({
          cartState,
          isMemberLoggedIn,
          mode: environment.isPreview ? 'preview' : 'live_site',
          navigationTarget,
        })
      );
    },
    reportOloLiveSiteOloPageLoadedBiEvent: ({
      isMemberLoggedIn,
      menus,
      refferalInfo,
    }: LiveSiteOloPageLoadedParams) => {
      const { isPreview } = environment;
      const { numOfItems, numOfSections } = getAmountOfSectionsAndItems(menus);
      const isDelivery = dispatchState.selectedDispatchType === DispatchType.DELIVERY;

      const eventParams: OloPageLoadedParams = {
        cartState: getAmountOfItemsInCart(),
        defaultSelectedFulfillment: LivesiteFulfillmentType[dispatchState.selectedDispatchType],
        defaultSelectedTimeSlot: state.initialTimeSlotText,
        deliveryFee:
          typeof dispatchState.dispatchInfo.selectedTimeSlot?.fulfillmentDetails.fee !== 'undefined'
            ? Number(dispatchState.dispatchInfo.selectedTimeSlot?.fulfillmentDetails.fee)
            : undefined,
        isDeliveryAvailable: dispatchState.isDispatchAvailable(DispatchType.DELIVERY),
        isDeliveryConfig: dispatchState.isDispatchConfigured(DispatchType.DELIVERY),
        isDeafultAddress: !isDelivery
          ? dispatchState.dispatchesInfo[DispatchType.PICKUP]?.address?.formattedAddress ===
            DEFAULT_ADDRESS
          : undefined,
        isMemberLoggedIn,
        minOrderDisplayed:
          dispatchState.dispatchInfo?.selectedTimeSlot?.fulfillmentDetails.minOrderPrice?.toString(),
        isPickupAvailable: dispatchState.isDispatchAvailable(DispatchType.PICKUP),
        isPickupConfig: dispatchState.isDispatchConfigured(DispatchType.PICKUP),
        mode: isPreview ? LivesitePageLoadModeType.PREVIEW : LivesitePageLoadModeType.LIVE_SITE,
        numDishes: numOfItems,
        numMenus: menus?.length,
        numSections: numOfSections,
        operationId: state.operation?.id,
        statusDisplayed: dispatchState.hasAvailableDispatches
          ? LivesitePageLoadStatusDisplayedType.ACCEPTING_ORDERS
          : LivesitePageLoadStatusDisplayedType.CLOSED,
        refferalInfo,
      };
      biLogger?.report(oloLiveSiteOloPageLoaded(eventParams));
    },
    reportRestaurantsUouPageStartedLoadingBiEvent: () => {
      const eventParams: OloPageStartedLoadingParams = {
        hosting: getHostingEnvironment(environment, origin?.subType),
        pageName: PageName.ONLINE_ORDERING,
        product: Product.OLO,
        widgetId: PAGE_DATA.widgetId,
        widgetInstanceId,
        widgetName: WidgetName.OLO,
      };
      biLogger?.report(restaurantsUouPageStartedLoading(eventParams));
    },
    reportRestaurantsUouPageFinishedLoadingBiEvent: () => {
      const eventParams: OloPageFinishedLoadingParams = {
        hosting: getHostingEnvironment(environment, origin?.subType),
        pageName: PageName.ONLINE_ORDERING,
        product: Product.OLO,
        widgetId: PAGE_DATA.widgetId,
        widgetInstanceId,
        widgetName: WidgetName.OLO,
      };
      biLogger?.report(restaurantsUouPageFinishedLoading(eventParams));
    },
    reportOloLiveSiteClickOnFulfillmentBiEvent: ({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      origin,
      dispatchType,
      isMemberLoggedIn,
    }: ClickOnFulfillmentParams) => {
      const eventParams: OloLiveSiteClickOnFulfillmentParams = {
        cartState: getAmountOfItemsInCart(),
        isMemberLoggedIn,
        operationId: state.operation?.id,
        selectedFulfillment: dispatchType && LivesiteFulfillmentType[dispatchType],
        currentFulfillment: LivesiteFulfillmentType[dispatchState.selectedDispatchType],
        origin,
        statusDisplayed: dispatchState.hasAvailableDispatches
          ? LivesiteClickFulfillmentStatusDisplayedType.ACCEPTING_ORDERS
          : LivesiteClickFulfillmentStatusDisplayedType.CLOSED,
      };

      biLogger?.report(restaurantsOloLiveSiteClickOnFulfillment(eventParams));
    },
    reportOloLiveSiteDispatchModalOpenedBiEvent: ({
      schedulingType,
      isAsapDisplayed,
      availableDispatchTypes = [],
      isPreorderDisplayed,
      currentFulfillment,
      operationId,
      isMemberLoggedIn,
      selectedSchedulingRadioButton,
      selectedTime,
      businessDays,
    }: DispatchModalOpenedParams) => {
      const eventParams: OloLiveSiteDispatchModalOpenedParams = {
        operationId,
        schedulingType: LiveSiteDispatchModalOpenedSchedulingType[schedulingType],
        currentFulfillment: LivesiteFulfillmentType[currentFulfillment],
        isAsapDisplayed,
        isPreorderDisplayed,
        isDeliveryDisplayed: availableDispatchTypes.includes(DispatchType.DELIVERY),
        isPickupDisplayed: availableDispatchTypes.includes(DispatchType.PICKUP),
        isMemberLoggedIn,
        selectedRadioButton: getSelectedRadioButton(selectedSchedulingRadioButton),
        fromDateAndTime: selectedTime?.startTime?.valueOf(),
        untilDateAndTime: selectedTime?.endTime?.valueOf(),
        businessDays,
      };

      biLogger?.report(restaurantsOloLiveSiteDispatchModalOpened(eventParams));
    },
    reportOloLiveSiteClickOnItemBiEvent: ({
      itemId,
      itemName = '',
      menuId,
      sectionId,
      minItemPrice,
      operationId,
      isMenuItemAvailable,
      isItemInStock,
    }: ClickOnItemParams) => {
      const eventParams: OloLiveSiteClickOnItemParams = {
        itemId,
        itemName,
        menuId,
        sectionId,
        minItemPrice,
        operationId,
        isMenuItemAvailable,
        isItemInStock,
      };

      biLogger?.report(oloLiveSiteClickOnItem(eventParams));
    },
    reportOloLiveSiteItemModalOpenedBiEvent: ({
      itemId,
      itemName = '',
      menuId = '',
      sectionId = '',
      minItemPrice,
      num_labels,
      numModifiers = 0,
      numModifiersGroups = 0,
      numPriceVariations = 0,
      isMenuItemAvailable,
      isItemInStock,
      operationId,
    }: ItemModalOpenedParams) => {
      const eventParams: OloLiveSiteItemModalOpenedParams = {
        itemId,
        itemName,
        menuId,
        sectionId,
        minItemPrice,
        num_labels,
        numModifiers,
        numModifiersGroups,
        numPriceVariations,
        isMenuItemAvailable,
        isItemInStock,
        operationId,
      };

      biLogger?.report(oloLiveSiteItemModalOpened(eventParams));
    },
    reportOloLiveSiteAddDishToCartBiEvent: ({
      itemId,
      itemName,
      menuId,
      sectionId,
      num_labels,
      numSelectedModifiers,
      priceVariationName,
      selectedModifiersList,
      amountOfItems,
      itemPrice,
      isSuccess,
      labelsList,
      operationId,
    }: AddDishToCartParams) => {
      const eventParams: OloLiveSiteAddDishToCartParams = {
        itemId,
        itemName,
        menuId,
        sectionId,
        num_labels,
        numSelectedModifiers,
        priceVariationName,
        selectedModifiersList,
        amountOfItems,
        priceOfItem: itemPrice.toString(),
        isSuccess,
        labelsList,
        operationId,
      };

      biLogger?.report(oloLiveSiteAddDishToCart(eventParams));
    },
    reportOloLiveSiteClicksOnMenuDropdownAndOptionBiEvent: ({
      buttonName,
      currentMenuId,
      currentMenuName,
      currentMenuNumber,
      operationId = '',
      numTotalMenus,
      menuNumber,
      menuName,
      menuId,
    }: ClicksOnMenuDropdownAndOptionParams) => {
      const eventParams: OloLiveSiteClicksOnMenuDropdownAndOptionInsideParams = {
        buttonName,
        currentMenuId,
        currentMenuName,
        currentMenuNumber,
        operationId,
        menuNumber,
        menuName,
        menuId,
        numTotalMenus,
      };

      biLogger?.report(
        restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuDropdownAndOtherMenusInside(eventParams)
      );
    },
    reportOloLiveSiteClicksOnMenuBarBiEvent: ({
      currentSectionNumber,
      numTotalSections,
      operationId = '',
      sectionId,
      sectionName,
    }: ClicksOnMenuBarParams) => {
      const eventParams: OloLiveSiteClicksOnMenuBarParams = {
        currentSectionNumber,
        numTotalSections,
        operationId,
        sectionId,
        sectionName,
      };

      biLogger?.report(restaurantsOloLiveSiteOnlineOrdersPageClicksOnMenuBar(eventParams));
    },
    reportOloLiveSiteClicksInsideDispatchModalBiEvent: ({
      dispatchType,
      isPreorder,
      buttonType,
      operationId,
    }: ClicksInsideDispatchModalParams) => {
      const eventParams: OloLiveSiteClicksInsideDispatchModalParams = {
        currentFulfillment: LivesiteFulfillmentType[dispatchType],
        isPreorder,
        buttonType,
        operationId,
      };

      biLogger?.report(restaurantsOloLiveSiteClicksInsideDispatchModal(eventParams));
    },
    reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent: ({
      dispatchType,
      errorType,
      errorMessage = '',
    }: ErrorDisplayedInDispatchModalParams) => {
      const eventParams: OloLiveSiteErrorDisplayedInDispatchModalParams = {
        currentFulfillment: LivesiteFulfillmentType[dispatchType],
        errorType: getErrorType(errorType),
        errorMessage,
      };

      biLogger?.report(restaurantsOloLiveSiteErrorDisplayedInDispatchModal(eventParams));
    },

    reportOloLiveSiteDispatchModalClosedBiEvent: ({
      dispatchType,
      triggerName,
      operationId,
      selectedSchedulingRadioButton,
      isAsapDisplayed,
      isPreorderDisplayed,
      schedulingType,
      availableDispatchTypes = [],
      isMemberLoggedIn,
      selectedTime,
      businessDays,
    }: DispatchModalClosedParams) => {
      const selectedRadioButton =
        selectedSchedulingRadioButton && getSelectedRadioButton(selectedSchedulingRadioButton);
      const eventParams: OloLiveSiteDispatchModalClosedParams = {
        currentFulfillment: LivesiteFulfillmentType[dispatchType],
        triggerName,
        operationId,
        schedulingType: LiveSiteDispatchModalClosedSchedulingType[schedulingType],
        selectedRadioButton: selectedRadioButton
          ? LiveSiteDispatchModalClosedSelectedRadioButton[selectedRadioButton]
          : undefined,

        isAsapDisplayed,
        isPreorderDisplayed,
        isDeliveryDisplayed: availableDispatchTypes.includes(DispatchType.DELIVERY),
        isPickupDisplayed: availableDispatchTypes.includes(DispatchType.PICKUP),
        isMemberLoggedIn,
        fromDateAndTime: selectedTime?.startTime?.valueOf(),
        untilDateAndTime: selectedTime?.endTime?.valueOf(),
        businessDays,
      };

      biLogger?.report(restaurantsOloLiveSiteDispatchModalClosed(eventParams));
    },
    reportOloGenericDebugBiEvent: ({ subjectType, value }: GenericDebugEventParams) => {
      const data = { ...value, resolution };
      const eventParams: OloGenericDebugParams = {
        subjectType,
        hosting: getHostingEnvironment(environment, origin?.subType),
        value: JSON.stringify(data),
      };
      biLogger?.report(oloGenericDebugEvent(eventParams));
    },
    reportOloEditorInstallationStepsEvent: ({
      step,
      value,
      isFirstInstall,
      isInstallationRetry,
      isStudio,
      appDefId,
      msid,
      errorMessage,
      lightboxId,
      lightboxTitle,
      lightboxWidgetId,
      lightboxLayout,
      lightboxParentRef,
    }: EditorInstallationStepsParams) => {
      const eventParams: OloEditorInstallationStepsParams = {
        origin: JSON.stringify(origin),
        step,
        value,
        isFirstInstall,
        isInstallationRetry,
        isStudio,
        appDefId,
        msid,
        errorMessage,
        lightboxId,
        lightboxTitle,
        lightboxWidgetId,
        lightboxLayout,
        lightboxParentRef,
      };
      biLogger?.report(oloEditorInstallationSteps(eventParams));
    },
    reportOloEditorMenuReorderBiEvent: ({
      menuId,
      menuName,
      numMenus,
      indexListBeforeChange,
      indexListAfterChange,
    }: EditorMenuReorderParams) => {
      const eventParams: OloEditorMenuReorderParams = {
        menuId,
        menuName,
        numMenus,
        indexListBeforeChange,
        indexListAfterChange,
      };

      biLogger?.report(oloEditorMenuReorder(eventParams));
    },
    reportOloEditorPageAddedBiEvent: ({
      operationId,
      isDuplicated,
      isUpdated,
    }: EditorPageAddedParams) => {
      const eventParams = {
        operationId,
        isDuplicated,
        isUpdated,
      };

      biLogger?.report(oloEditorOrderPageWasAddedToEditorSrc159Evid561(eventParams));
    },
    reportOloEditorPageDeletedBiEvent: ({ operationId, isSuccessful }: EditorPageDeletedParams) => {
      const eventParams = {
        operationId,
        is_successful: isSuccessful,
      };

      biLogger?.report(oloEditorOrderPageWasDeletedFromEditorSrc159Evid563(eventParams));
    },
  };

  return biReporterObject;
};
